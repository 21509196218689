import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as THREE from 'three';
import Logo from "./Logo";

export default function Footer() {
  const canvasRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const rendererRef = useRef();
  const sceneRef = useRef();
  const cameraRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Configuração da cena Three.js
    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(
      75,
      canvas.clientWidth / canvas.clientHeight,
      0.1,
      1000
    );
    camera.position.z = 5;
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      antialias: true,
    });
    renderer.setSize(canvas.clientWidth, canvas.clientHeight);
    rendererRef.current = renderer;

    // Criação do sistema de partículas (simula uma rede neural)
    const particleCount = 2000;
    const positions = new Float32Array(particleCount * 3);
    const colors = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
      const index = i * 3;
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.random() * Math.PI;
      const r = 2 + Math.random() * Math.sin(phi) * 0.5;

      positions[index] = r * Math.sin(phi) * Math.cos(theta);
      positions[index + 1] = r * Math.cos(phi);
      positions[index + 2] = r * Math.sin(phi) * Math.sin(theta);

      if (Math.random() > 0.5) {
        // Cor rosa
        colors[index] = 1;
        colors[index + 1] = 0;
        colors[index + 2] = 1;
      } else {
        // Cor ciano
        colors[index] = 0;
        colors[index + 1] = 1;
        colors[index + 2] = 1;
      }
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

    const material = new THREE.PointsMaterial({
      size: 0.05,
      vertexColors: true,
    });

    const particleSystem = new THREE.Points(geometry, material);
    scene.add(particleSystem);

    // Fatores de esticamento (apenas para X e Y)
    const stretchX = 1.5;
    const stretchY = 1.5;

    // Função de animação
    const animate = () => {
      animationFrameIdRef.current = requestAnimationFrame(animate);
      particleSystem.rotation.y += 0.005;
      particleSystem.rotation.x += 0.002;
      const time = Date.now() * 0.001;
      const scale = 1 + Math.sin(time) * 0.1;
      // Aplica o esticamento apenas nos eixos X e Y
      particleSystem.scale.set(scale * stretchX, scale * stretchY, scale);
      renderer.render(scene, camera);
    };

    animate();

    // Responsividade: utiliza as dimensões do container pai
    const handleResize = () => {
      const width = canvas.parentElement.clientWidth;
      const height = canvas.parentElement.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameIdRef.current);
      renderer.dispose();
    };
  }, []);

  return (
    <footer className="bg-[#0a0a1a] text-white">
      {/* Seção Call-to-Action com animação de fundo */}
      

      {/* Seção de Informações e Newsletter */}
      <div className="px-4 sm:px-6 bg-gray-900">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-12 border-t border-gray-800">
          {/* Bloco com Logo e Links Institucionais */}
          <div>
            <div className="mb-4">
              <Logo />
            </div>
            <div className="text-sm text-gray-500 flex flex-wrap gap-2">
              <Link to="/terms" className="hover:text-gray-300 transition duration-150">
                Terms
              </Link>
              <span>·</span>
              <Link to="/privacy" className="hover:text-gray-300 transition duration-150">
                Privacy Policy
              </Link>
            </div>
          </div>

          {/* Bloco Newsletter */}
          <div className="text-center md:text-right">
            <h6 className="text-lg font-medium mb-2">Receba Novidades</h6>
            <p className="text-sm text-gray-400 mb-4">
              Inscreva-se para ficar por dentro das últimas atualizações e ofertas.
            </p>
            <form className="flex flex-col sm:flex-row items-center justify-center">
              <input
                type="email"
                className="w-full sm:w-auto px-4 py-2 rounded-full text-gray-800 focus:outline-none"
                placeholder="Seu email"
                required
              />
              <button
                type="submit"
                className="mt-4 sm:mt-0 sm:ml-4 px-6 py-2 rounded-full bg-gradient-to-r from-cyan-500 to-purple-600 hover:from-cyan-600 hover:to-purple-700 transition duration-300"
              >
                Inscrever-se
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Rodapé com Redes Sociais e Copyright */}
      <div className="bg-gray-800">
        <div className="container mx-auto px-4 sm:px-6 lg:px-12 py-6 flex flex-col md:flex-row items-center justify-between">
          <div className="text-sm text-gray-400">
            &copy; {new Date().getFullYear()} Nerp.com.br. Todos os direitos reservados.
          </div>
          <ul className="flex space-x-4 mt-4 md:mt-0">
            <li>
              <a
                href="#0"
                aria-label="Twitter"
                className="text-gray-400 hover:text-cyan-400 transition duration-300"
              >
                <svg className="w-6 h-6 fill-current" viewBox="0 0 32 32">
                  <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="#0"
                aria-label="Github"
                className="text-gray-400 hover:text-cyan-400 transition duration-300"
              >
                <svg className="w-6 h-6 fill-current" viewBox="0 0 32 32">
                  <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="#0"
                aria-label="Facebook"
                className="text-gray-400 hover:text-cyan-400 transition duration-300"
              >
                <svg className="w-6 h-6 fill-current" viewBox="0 0 32 32">
                  <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
