import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import * as THREE from 'three';
import { FaEnvelope, FaUser, FaPhone } from 'react-icons/fa';
import { db } from '../components/Firebase'; // Importa a configuração do Firebase
import { collection, addDoc } from 'firebase/firestore';

const inputVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const ContactForm = () => {
  const formRef = useRef(null);
  const canvasRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const rendererRef = useRef();
  const sceneRef = useRef();
  const cameraRef = useRef();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(75, canvas.clientWidth / canvas.clientHeight, 0.1, 1000);
    camera.position.z = 5;
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      antialias: true,
    });
    renderer.setSize(canvas.clientWidth, canvas.clientHeight);
    rendererRef.current = renderer;

    const particleCount = 2000;
    const positions = new Float32Array(particleCount * 3);
    const colors = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
      const index = i * 3;
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.random() * Math.PI;
      const r = 2 + Math.random() * Math.sin(phi) * 0.5;

      positions[index] = r * Math.sin(phi) * Math.cos(theta);
      positions[index + 1] = r * Math.cos(phi);
      positions[index + 2] = r * Math.sin(phi) * Math.sin(theta);

      if (Math.random() > 0.5) {
        colors[index] = 1;
        colors[index + 1] = 0;
        colors[index + 2] = 1;
      } else {
        colors[index] = 0;
        colors[index + 1] = 1;
        colors[index + 2] = 1;
      }
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

    const material = new THREE.PointsMaterial({
      size: 0.05,
      vertexColors: true,
    });

    const particleSystem = new THREE.Points(geometry, material);
    scene.add(particleSystem);

    const stretchX = 1.5;
    const stretchY = 1.5;

    const animate = () => {
      animationFrameIdRef.current = requestAnimationFrame(animate);
      particleSystem.rotation.y += 0.005;
      particleSystem.rotation.x += 0.002;
      const time = Date.now() * 0.001;
      const scale = 1 + Math.sin(time) * 0.1;
      particleSystem.scale.set(scale * stretchX, scale * stretchY, scale);
      renderer.render(scene, camera);
    };

    animate();

    const handleResize = () => {
      const width = canvas.parentElement.clientWidth;
      const height = canvas.parentElement.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameIdRef.current);
      renderer.dispose();
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(null);

    try {
      await addDoc(collection(db, 'messages'), formData);
      setSuccess('Mensagem enviada com sucesso!');
      setFormData({ name: '', email: '', phone: '', message: '' });
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      setSuccess('Erro ao enviar a mensagem.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-[#14142B] text-white py-16 relative overflow-hidden">
      <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full opacity-20" />
      <div className="container mx-auto px-4 md:px-6 lg:px-12 relative z-10">
        <motion.h2 className="text-2xl md:text-2xl font-extrabold text-center mb-4 text-cyan-400">
          Quer entrar na fila para ser um mentorado?
        </motion.h2>
        <motion.p className="text-center text-gray-300 mb-12 max-w-3xl mx-auto">
          Estamos prontos para ajudar sua empresa a crescer.
        </motion.p>

        <motion.form
          ref={formRef}
          onSubmit={handleSubmit}
          className="max-w-lg mx-auto bg-black bg-opacity-50 p-8 rounded-lg shadow-xl"
        >
          <div className="mb-4">
            <label className="block text-gray-400 mb-2">Nome</label>
            <div className="relative">
              <FaUser className="absolute left-3 top-3 text-gray-400" />
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Seu Nome"
                className="pl-10 pr-4 py-2 w-full bg-gray-700 text-white rounded"
                required
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-400 mb-2">Email</label>
            <div className="relative">
              <FaEnvelope className="absolute left-3 top-3 text-gray-400" />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Seu Email"
                className="pl-10 pr-4 py-2 w-full bg-gray-700 text-white rounded"
                required
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-400 mb-2">Telefone</label>
            <div className="relative">
              <FaPhone className="absolute left-3 top-3 text-gray-400" />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Seu Telefone"
                className="pl-10 pr-4 py-2 w-full bg-gray-700 text-white rounded"
              />
            </div>
          </div>

          <motion.button type="submit" className="w-full py-3 bg-cyan-500 text-white font-bold rounded">
            {loading ? 'Enviando...' : 'Enviar Mensagem'}
          </motion.button>
        </motion.form>

        {success && <p className="text-center mt-4 text-cyan-400">{success}</p>}
      </div>
    </section>
  );
};

export default ContactForm;
