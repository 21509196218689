import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { DadosContext } from '../../common/DadosContext';

const Conversations = () => {
  const { tokens } = useContext(DadosContext);
  const [conversations, setConversations] = useState([]);
  const [pagination, setPagination] = useState({ limit: 20, offset: 0, total: 0 });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Filtros (se já estiverem implementados)
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Estados para resposta
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answerText, setAnswerText] = useState('');

  
  const fetchConversations = async () => {
    setLoading(true);
    setError(null);
    const token = tokens.find((t) => t.id === 'magalu-chat')?.token;
    if (!token) {
      setError('Token não encontrado');
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post('https://nerp.com.br/magalu/msg', {
        accessToken: token,
        status,
        startDate,
        endDate,
        
      });
      let { results, meta } = response.data;
      results = results.sort(
        (a, b) => new Date(b.question.when_at) - new Date(a.question.when_at)
      );
      setConversations(results);
      console.log(results)
      setPagination({
        limit: meta.page.limit,
        offset: meta.page.offset,
        total: meta.page.total || results.length,
      });
    } catch (err) {
      console.error('Erro ao carregar conversas:', err);
      setError('Erro ao carregar conversas.');
    } finally {
      setLoading(false);
    }
  };

  // Abre o modal de resposta para a pergunta selecionada
  const handleOpenAnswer = (conv) => {
    setSelectedQuestion(conv);
    setAnswerText(''); // Limpa o campo de resposta
  };

  // Envia a resposta para o backend
  const handleSendAnswer = async () => {
    const token = tokens.find((t) => t.id === 'magalu-chat')?.token;
    if (!token) {
      alert('Token não encontrado');
      return;
    }
    try {
      const response = await axios.post(
        `https://nerp.com.br/magalu/questions/${selectedQuestion.id}/answer`,
        {
          accessToken: token,
          message: answerText,
          // Se desejar permitir entrada manual para external_id, inclua aqui;
          // Caso contrário, o backend gerará um automaticamente.
          owner: {
            name: 'LinhaEvo', // Substitua pelo nome do usuário logado ou permita edição
            external_id: 'ev', // Substitua pelo ID externo do usuário
          },
        }
      );
      console.log('Resposta enviada com sucesso:', response.data);
      setSelectedQuestion(null);
      setAnswerText('');
      fetchConversations(); // Atualiza as conversas para refletir a resposta enviada
    } catch (err) {
      console.error('Erro ao enviar resposta:', err);
      alert('Erro ao enviar resposta.');
    }
  };

  const handlePageChange = (newOffset) => {
    setPagination((prev) => ({ ...prev, offset: newOffset }));
  };

  return (
    <div className="py-36 px-4 bg-gray-100 text-gray-800 min-h-screen">
      <h2 className="text-3xl font-bold text-center mb-8">Conversas</h2>

      {/* Filtros */}
      <div className="flex flex-wrap items-end justify-center gap-4 mb-8">
        <div className="flex flex-col">
          <label className="mb-1 font-semibold">Status:</label>
          <select
            className="p-2 border border-gray-300 rounded"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="APPROVED">Aprovado</option>
            <option value="WAITING_RESPONSE">Pendente</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-semibold">Data de Início:</label>
          <input
            type="date"
            className="p-2 border border-gray-300 rounded"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-semibold">Data de Fim:</label>
          <input
            type="date"
            className="p-2 border border-gray-300 rounded"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <button
          onClick={fetchConversations}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Filtrar
        </button>
      </div>

      {loading && <p className="text-center text-lg">Carregando...</p>}
      {error && <p className="text-center text-red-600">{error}</p>}

      {/* Lista de Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {conversations.map((conv) => (
          <div key={conv.id} className="bg-white shadow rounded-lg overflow-hidden flex flex-col">
            <img
              src={conv.subject.extra.url_img}
              alt={conv.subject.extra.name}
              className="w-full h-48 object-cover"
            />
            <div className="p-4 flex flex-col flex-grow">
              <h3 className="text-xl font-bold text-blue-600 mb-2">
                {conv.subject.extra.name}
              </h3>
              <p className="text-sm text-gray-600 mb-2">
                {conv.subject.extra.description}
              </p>
              <p className="mb-2">
                <span className="font-semibold">Pergunta:</span> {conv.question.message}
              </p>
              {conv.answer && (
                <p className="mb-2">
                  <span className="font-semibold">Resposta:</span> {conv.answer.message}
                </p>
              )}
              <p className="text-xs text-gray-500 mb-2">
                <span className="font-semibold">Data:</span>{' '}
                {new Date(conv.question.when_at).toLocaleString('pt-BR')}
              </p>
              <a
                href={conv.subject.extra.url}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-auto text-blue-600 font-semibold hover:underline"
              >
                Ver Produto
              </a>
              {/* Botão para responder a pergunta */}
              <button
                onClick={() => handleOpenAnswer(conv)}
                className="mt-2 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
              >
                Responder
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Paginação */}
      <div className="flex justify-center items-center mt-8 gap-4">
        <button
          onClick={() => handlePageChange(pagination.offset - pagination.limit)}
          disabled={pagination.offset <= 0}
          className={`px-4 py-2 rounded text-white ${
            pagination.offset <= 0
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700'
          }`}
        >
          Anterior
        </button>
        <span>
          Página {Math.floor(pagination.offset / pagination.limit) + 1}
        </span>
        <button
          onClick={() => handlePageChange(pagination.offset + pagination.limit)}
          disabled={pagination.offset + pagination.limit >= pagination.total}
          className={`px-4 py-2 rounded text-white ${
            pagination.offset + pagination.limit >= pagination.total
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700'
          }`}
        >
          Próximo
        </button>
      </div>

      {/* Modal para responder a pergunta */}
      {selectedQuestion && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md w-96">
            <h3 className="text-lg font-semibold mb-4">
              Responder: {selectedQuestion.subject.extra.name}
            </h3>
            <textarea
              value={answerText}
              onChange={(e) => setAnswerText(e.target.value)}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Digite sua resposta aqui..."
            ></textarea>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setSelectedQuestion(null)}
                className="px-4 py-2 mr-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancelar
              </button>
              <button
                onClick={handleSendAnswer}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Conversations;
