import React from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Area,
} from 'recharts';

// Tooltip customizado para melhorar a exibição dos dados
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#333',
          border: '1px solid #8884d8',
          padding: '10px',
          borderRadius: '5px',
          color: '#fff',
        }}
      >
        <p style={{ margin: 0, fontWeight: 'bold' }}>{label}</p>
        <p style={{ margin: 0 }}>Total: {Number(payload[0].value).toFixed(2)}</p>
      </div>
    );
  }
  return null;
};

const PredictionsChart = ({ previsoes }) => {
  // Mapeia as previsões para o formato necessário
  const dadosParaMostrar = previsoes.map((total, index) => ({
    date: `Dia ${index + 1}`,
    total: parseFloat(total),
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={dadosParaMostrar}
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
      >
        {/* Definição do gradiente para preencher a área */}
        <defs>
          <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>

        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />

        {/* Linha com ponto ativo */}
        <Line
          type="monotone"
          dataKey="total"
          stroke="#8884d8"
          strokeWidth={2}
          activeDot={{ r: 8 }}
        />

        {/* Área preenchida com gradiente */}
        <Area
          type="monotone"
          dataKey="total"
          stroke="none"
          fill="url(#colorTotal)"
          fillOpacity={0.3}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default PredictionsChart;
