import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { DadosContext } from "../common/DadosContext";

const Categorias = () => {
  const { tokens } = useContext(DadosContext);
  const [categories, setCategories] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [items, setItems] = useState([]); // Estado para armazenar os itens
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Função para buscar as categorias principais
  const fetchCategories = async () => {
    const token = tokens.find((t) => t.id === "mercado_livre")?.token;
    if (!token) {
      alert("Digite um ID de vendedor!");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(
        "https://nerp.com.br/mercado-livre/categorias",
        { accessToken: token }
      );
      const sortedCategories = response.data.sort(
        (a, b) => (b.total_items_in_this_category || 0) - (a.total_items_in_this_category || 0)
      );
      setCategories(sortedCategories);
      setBreadcrumb([]);
      setSelectedCategory(null);
      setItems([]); // Limpar itens ao voltar para as categorias principais
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar os detalhes de uma categoria
  const fetchCategoryDetails = async (categoryId, categoryName) => {
    const token = tokens.find((t) => t.id === "mercado_livre")?.token;
    if (!token) {
      alert("Digite um ID de vendedor!");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(
        "https://nerp.com.br/mercado-livre/categoria/detalhes",
        { accessToken: token, categoryId }
      );
      const sortedChildrenCategories = response.data.children_categories.sort(
        (a, b) => b.total_items_in_this_category - a.total_items_in_this_category
      );
      setSelectedCategory({
        ...response.data,
        children_categories: sortedChildrenCategories,
      });
      setBreadcrumb((prev) => [...prev, { id: categoryId, name: categoryName }]);
      fetchCategoryItems(categoryId); // Buscar itens da categoria
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar os itens de uma categoria
  const fetchCategoryItems = async (categoryId) => {
    const token = tokens.find((t) => t.id === "mercado_livre")?.token;
    if (!token) {
      alert("Digite um ID de vendedor!");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(
        "https://nerp.com.br/mercado-livre/categoria/itens",
        { accessToken: token, categoryId }
      );
      setItems(response.data.results);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Função para voltar para a categoria anterior ou para as categorias principais
  const goBack = () => {
    if (breadcrumb.length > 1) {
      const newBreadcrumb = [...breadcrumb];
      newBreadcrumb.pop();
      setBreadcrumb(newBreadcrumb);
      const lastCategory = newBreadcrumb[newBreadcrumb.length - 1];
      fetchCategoryDetails(lastCategory.id, lastCategory.name);
    } else {
      fetchCategories();
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen text-lg">
        Carregando...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen text-lg text-red-500">
        Erro: {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Categorias do Mercado Livre</h1>

      {/* Breadcrumb para navegação */}
      {breadcrumb.length > 0 && (
        <div className="flex items-center mb-4 text-gray-600">
          <button
            onClick={goBack}
            className="text-blue-500 hover:underline mr-2"
          >
            Voltar
          </button>
          {breadcrumb.map((crumb, index) => (
            <span key={crumb.id}>
              {index > 0 && " > "}
              <span>{crumb.name}</span>
            </span>
          ))}
        </div>
      )}

      {/* Exibição das categorias */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {selectedCategory
          ? selectedCategory.children_categories.map((child) => (
              <div
                key={child.id}
                className="p-4 border rounded-lg shadow-md bg-white cursor-pointer hover:shadow-lg transition-shadow"
                onClick={() => fetchCategoryDetails(child.id, child.name)}
              >
                <h2 className="text-lg font-bold">{child.name}</h2>
                <p className="text-gray-500 text-sm">
                  {child.total_items_in_this_category.toLocaleString()} itens
                </p>
              </div>
            ))
          : categories.map((category) => (
              <div
                key={category.id}
                className="p-4 border rounded-lg shadow-md bg-white cursor-pointer hover:shadow-lg transition-shadow"
                onClick={() => fetchCategoryDetails(category.id, category.name)}
              >
                <h2 className="text-lg font-bold">{category.name}</h2>
                <p className="text-gray-500 text-sm">
                  {category.total_items_in_this_category
                    ? `${category.total_items_in_this_category.toLocaleString()} itens`
                    : "Sem informações"}
                </p>
              </div>
            ))}
      </div>

      {/* Itens da categoria */}
      {items.length > 0 && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4">Itens na Categoria</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {items.map((item) => (
              <div
                key={item.id}
                className="p-4 border rounded-lg shadow-md bg-white"
              >
                <img
                  src={item.thumbnail}
                  alt={item.title}
                  className="w-full h-40 object-cover mb-2"
                />
                <h4 className="font-bold text-lg">{item.title}</h4>
                <p className="text-sm text-gray-500">
                  {item.price.toLocaleString()} {item.currency_id}
                </p>
                <a
                  href={item.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline text-sm"
                >
                  Ver Item
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Categorias;
