import React from 'react';

const Statistics = ({ stats }) => (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 p-4 bg-gray-100 rounded-lg shadow-md">
        <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-700">New Orders</h3>
            <p className="text-2xl font-bold text-blue-500">{stats.new}</p>
        </div>
        <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-700">Approved Orders</h3>
            <p className="text-2xl font-bold text-green-500">{stats.approved}</p>
        </div>
        <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-700">Cancelled Orders</h3>
            <p className="text-2xl font-bold text-red-500">{stats.cancelled}</p>
        </div>
        <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-700">Finished Orders</h3>
            <p className="text-2xl font-bold text-yellow-500">{stats.finished}</p>
        </div>
    </div>
);

export default Statistics;
