import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { FaSearch, FaBullhorn, FaStore, FaCode, FaChartLine } from 'react-icons/fa';
import Trends from '../seo/trends';

const containerVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const cardVariants = {
  hidden: { opacity: 0, y: 50, scale: 0.95 },
  show: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { type: 'spring', bounce: 0.3, duration: 0.8 },
  },
};

const ServicesSection = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    let lines = [];
    const lineCount = 10;
    const waveHeight = 30;
    const speed = 0.005;
    let animationProgress = 3;

    function initializeWaves() {
      lines = [];
      for (let i = 0; i < lineCount; i++) {
        lines.push({
          frequency: 0.015 + i * 0.005,
          amplitude: waveHeight - i * 10,
          phase: (i * Math.PI) / 200,
        });
      }
    }

    let animationFrameId;

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      animationProgress += speed;
      const width = canvas.width;

      lines.forEach((line) => {
        ctx.beginPath();
        ctx.lineWidth = 1.5;
        ctx.strokeStyle = 'rgba(138, 103, 187)';

        for (let x = 0; x <= width; x += 2) {
          const y =
            canvas.height / 2 +
            Math.sin(x * line.frequency + animationProgress + line.phase) * line.amplitude;

          x === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);
        }
        ctx.stroke();
      });

      animationFrameId = requestAnimationFrame(animate);
    }

    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight / 3;
      initializeWaves();
    }

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <section className="bg-[#14142B] text-white py-16 relative overflow-hidden min-h-screen">
      <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full opacity-50 z-0"></canvas>
      <div className="container mx-auto px-4 md:px-6 lg:px-12 relative z-10">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-2xl md:text-2xl font-extrabold text-center mb-4 text-cyan-400"
        >
          Soluções para Importadores e Indústrias
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-center text-gray-300 mb-12 max-w-3xl mx-auto"
        >
          Ajudamos sua empresa a aumentar as vendas online e ganhar visibilidade no digital por meio de estratégias avançadas.
        </motion.p>

        <motion.div
          className="grid gap-8 md:grid-cols-2 lg:grid-cols-3"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <motion.div className="bg-black bg-opacity-70 p-8 rounded-lg shadow-lg text-center" variants={cardVariants}>
            <FaSearch className="text-cyan-400 text-5xl mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-cyan-400">SEO e Posicionamento Digital</h3>
            <p className="text-gray-400">Aumente sua visibilidade e melhore suas vendas online.</p>
          </motion.div>

          <motion.div className="bg-black bg-opacity-70 p-8 rounded-lg shadow-lg text-center" variants={cardVariants}>
            <FaBullhorn className="text-purple-400 text-5xl mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-purple-400">Gestão de Tráfego Pago</h3>
            <p className="text-gray-400">Campanhas otimizadas para atrair clientes qualificados.</p>
          </motion.div>

          <motion.div className="bg-black bg-opacity-70 p-8 rounded-lg shadow-lg text-center" variants={cardVariants}>
            <FaStore className="text-green-400 text-5xl mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-green-400">Integração com Marketplaces</h3>
            <p className="text-gray-400">Conectamos sua empresa a grandes marketplaces.</p>
          </motion.div>
        </motion.div>
        
      </div>
    </section>
  );
};

export default ServicesSection;