import React, {
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
  } from 'react';
  import axios from 'axios';
  import dayjs from 'dayjs';
  import { DadosContext } from '../common/DadosContext';
  import { Button } from '@mui/material';
  import DateFilter from './DateFilter';
  import {
    agruparVendasPorData,
    detectarAnomalias,
    previsaoVendasTensorFlow,
  } from './utils/analytics';
  import SalesChart from './SalesChart';
  import PredictionsChart from './PredictionsChart';
  import AnomaliesChart from './AnomaliesChart';
  import MovingAverageChart from './MovingAverageChart';
  import TopProductsCard from './TopProductsCard';
  import { Loader } from '../components/layout/Loader';
  import CurvaABCTable from './CurvaABCTable';
  import { useAuth } from '../common/AuthContext';
  
  // Componente para renderizar os cards de informação
  const InfoCard = ({ title, value, gradient }) => (
    <div
      className={`bg-gradient-to-r ${gradient} p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out text-center`}
    >
      <h3 className="text-lg font-bold text-white">{title}</h3>
      <p className="text-3xl font-bold text-white">{value}</p>
    </div>
  );
  
  const PedidoMagalu = () => {
    const { accessCodeMagalu } = useAuth();
    const { tokens } = useContext(DadosContext);
    const [userData, setUserData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [previsoes, setPrevisoes] = useState([]);
    const [mediaMovel, setMediaMovel] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(dayjs().startOf('year').toDate());
    const [endDate, setEndDate] = useState(dayjs().endOf('month').toDate());
    const [topProducts, setTopProducts] = useState({
      topMaisVendidos: [],
      topMenosVendidos: [],
      topTendencias: [],
    });
  
    // Obtenção do token do Magalu a partir do contexto
    const token = useMemo(
      () => tokens.find((t) => t.id === 'magalu')?.token,
      [tokens]
    );
  
    // Função para buscar pedidos filtrados pelo intervalo de datas
    const fetchFilteredOrders = useCallback(async () => {
      if (!token) {
        setError('Token não encontrado');
        return;
      }
      setLoading(true);
      setError(null);
      try {
        const response = await axios.post(
          'https://nerp.com.br/magalu/pedidos',
          {
            accessToken: token,
            startDate: startDate,
            endDate: endDate,
          },
          { headers: { 'Content-Type': 'application/json' } }
        );
  
        // Mapeia os pedidos para a estrutura desejada
        const orders = response.data.map((order) => ({
          id: order.id,
          createdAt: order.created_at,
          purchasedAt: order.purchased_at,
          total: order.agreement?.total?.items / 100 || 0,
          sku: order.agreement?.items.map((item) => ({
            sku: item.sku?.code || 'SKU desconhecido',
          })),
          frete: order.agreement?.total?.deliveries / 100 || 0,
          desconto:
            order.agreement?.total?.discounts
              ? order.agreement.total.discounts / 100
              : 0,
        }));
        setUserData(orders);
      } catch (err) {
        setError(
          'Erro ao buscar pedidos: ' +
            (err.response?.data?.message || err.message)
        );
      } finally {
        setLoading(false);
      }
    }, [token, startDate, endDate]);

    console.log(userData)
  
    // Função para calcular os produtos mais vendidos e outras métricas
    const calcularTopProdutos = useCallback((data) => {
      const vendasPorProduto = data.reduce((acc, order) => {
        if (order.sku && Array.isArray(order.sku)) {
          order.sku.forEach((item) => {
            const sku = item.sku || 'SKU desconhecido';
            const total = order.total || 0;
            if (!acc[sku]) {
              acc[sku] = { sku, quantidade: 0, total: 0 };
            }
            acc[sku].quantidade += 1;
            acc[sku].total += total;
          });
        }
        return acc;
      }, {});
      const produtosOrdenados = Object.values(vendasPorProduto).sort(
        (a, b) => b.quantidade - a.quantidade
      );
      return {
        topMaisVendidos: produtosOrdenados.slice(0, 500),
        topMenosVendidos: produtosOrdenados.slice(-20).reverse(),
        topTendencias: produtosOrdenados.slice(0, 20),
      };
    }, []);
  
    // Processa os dados dos produtos para uso em outros componentes
    const processarDadosProdutos = useCallback((dados) => {
      return dados.reduce((acc, order) => {
        if (order.sku && Array.isArray(order.sku)) {
          order.sku.forEach((item) => {
            const sku = item.sku || 'SKU desconhecido';
            if (!acc[sku]) {
              acc[sku] = [];
            }
            acc[sku].push({
              total: order.total || 0,
              date: order.createdAt || order.purchasedAt || '',
              quantity: 1,
            });
          });
        }
        return acc;
      }, {});
    }, []);
  
    // Calcula a média móvel dos totais de venda
    const calcularMediaMovel = useCallback((dados, tamanhoJanela = 7) => {
      if (dados.length < tamanhoJanela) return [];
      const mediaMovel = [];
      for (let i = 0; i <= dados.length - tamanhoJanela; i++) {
        const janela = dados.slice(i, i + tamanhoJanela).map((item) => item.total);
        const media =
          janela.reduce((acc, val) => acc + val, 0) / tamanhoJanela;
        mediaMovel.push({
          date: dados[i + tamanhoJanela - 1].date,
          media,
        });
      }
      return mediaMovel;
    }, []);
  
    // Processa os dados assim que os pedidos são carregados
    useEffect(() => {
      if (userData.length) {
        // Agrupa as vendas por data
        const dadosAgrupados = agruparVendasPorData(userData);
        // Detecta anomalias nos dados agrupados
        const dadosComAnomalias = detectarAnomalias(dadosAgrupados);
        setFilteredData(dadosComAnomalias);
        // Executa a previsão de vendas utilizando TensorFlow
        previsaoVendasTensorFlow(dadosAgrupados, setPrevisoes);
        // Calcula a média móvel
        const mediaMovelCalculada = calcularMediaMovel(dadosAgrupados);
        setMediaMovel(mediaMovelCalculada);
        // Calcula os produtos top
        const topProdutosCalculados = calcularTopProdutos(userData);
        setTopProducts(topProdutosCalculados);
        console.log(userData)
      }
    }, [userData, calcularMediaMovel, calcularTopProdutos]);
  
    // Cálculos dos KPIs – utilizando useMemo para otimizar
    const totalVendas = useMemo(
      () => filteredData.reduce((acc, item) => acc + item.total, 0),
      [filteredData]
    );
    const mediaVendasDiaria = useMemo(
      () =>
        filteredData.length > 0
          ? (
              filteredData.reduce((acc, item) => acc + item.total, 0) /
              filteredData.length
            ).toFixed(2)
          : 0,
      [filteredData]
    );
    const diasComAnomalias = useMemo(
      () => filteredData.filter((item) => item.isAnomalia).length,
      [filteredData]
    );
    const maiorVenda = useMemo(
      () =>
        filteredData.length > 0
          ? filteredData.reduce((max, item) =>
              item.total > max.total ? item : max
            , filteredData[0])
          : null,
      [filteredData]
    );
    const previsaoTotal = useMemo(
      () => previsoes.reduce((acc, value) => acc + value, 0).toFixed(2),
      [previsoes]
    );
    const vendasHoje = useMemo(
      () =>
        filteredData
          .filter((item) => dayjs(item.date).isSame(dayjs(), 'day'))
          .reduce((acc, item) => acc + item.total, 0),
      [filteredData]
    );
    const totalDesconto = useMemo(
      () => userData.reduce((acc, item) => acc + item.desconto, 0),
      [userData]
    );
    const totalFrete = useMemo(
      () => userData.reduce((acc, item) => acc + item.frete, 0),
      [userData]
    );
    const productData = useMemo(
      () => processarDadosProdutos(userData),
      [userData, processarDadosProdutos]
    );

  
    // ----------------------------------------------------------------------------
    // Lógica de Recomendações – cria insights e ações com base nos KPIs
    const recommendations = useMemo(() => {
      const recs = [];
      // Se as vendas de hoje estiverem abaixo da metade da média diária
      if (vendasHoje < mediaVendasDiaria * 0.5) {
        recs.push(
          "As vendas de hoje estão significativamente abaixo da média diária. Considere lançar uma promoção ou investir em campanhas de marketing digital para impulsionar as vendas."
        );
      }
      // Se houver muitas anomalias (por exemplo, mais de 3 dias com anomalias)
      if (diasComAnomalias > 3) {
        recs.push(
          "Detectamos diversas anomalias nos dados. Revise possíveis problemas operacionais, erros de precificação ou oportunidades para ajustar sua estratégia de vendas."
        );
      }
      // Se os descontos aplicados forem superiores a 10% do total de vendas
      if (totalVendas > 0 && totalDesconto / totalVendas > 0.1) {
        recs.push(
          "Os descontos representam uma parcela significativa das vendas. Considere revisar sua política de descontos para melhorar as margens de lucro."
        );
      }
      // Se a previsão de vendas for muito superior ao total atual (indicando potencial de alta demanda)
      if (previsaoTotal > totalVendas * 1.2) {
        recs.push(
          "A previsão para os próximos 30 dias indica um aumento considerável na demanda. Prepare seu estoque e equipe para atender a esse crescimento."
        );
      }
      // Se os custos de frete forem superiores a 5% do total de vendas
      if (totalVendas > 0 && totalFrete / totalVendas > 0.05) {
        recs.push(
          "Os custos de frete estão impactando as vendas. Avalie a possibilidade de negociar melhores condições com transportadoras ou ajustar a política de frete grátis."
        );
      }
      return recs;
    }, [
      vendasHoje,
      mediaVendasDiaria,
      diasComAnomalias,
      totalDesconto,
      totalVendas,
      previsaoTotal,
      totalFrete,
    ]);
  
    // ----------------------------------------------------------------------------
    return (
      <div className="px-4 py-36 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-white min-h-screen">
        {loading ? (
          <Loader />
        ) : error ? (
          <div className="bg-red-700 p-6 rounded-lg shadow-lg mb-8">
            <p className="text-white font-semibold">{error}</p>
            <Button
              variant="contained"
              color="error"
              onClick={fetchFilteredOrders}
              className="mt-4 bg-red-500 hover:bg-red-600 transition duration-300 ease-in-out shadow-lg"
            >
              Tentar Novamente
            </Button>
          </div>
        ) : (
          <div>
            <header className="flex flex-col md:flex-row justify-between items-center mb-8">
              <h1 className="text-4xl font-bold text-white mb-4 md:mb-0">
                Dashboard de Vendas
              </h1>
              <div className="flex items-center gap-4">
                <DateFilter
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={fetchFilteredOrders}
                  disabled={loading}
                  className="bg-blue-600 hover:bg-blue-700 text-white transition duration-300 ease-in-out rounded-lg px-6 py-2"
                >
                  Buscar Pedidos
                </Button>
              </div>
            </header>
  
            {/* Cards de Métricas */}
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
              <InfoCard
                title="Total de Vendas"
                value={`R$ ${totalVendas.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                gradient="from-blue-500 via-purple-600 to-pink-500"
              />
              <InfoCard
                title="Média Diária"
                value={`R$ ${mediaVendasDiaria.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                gradient="from-green-500 via-teal-600 to-blue-500"
              />
              <InfoCard
                title="Dias com Anomalias"
                value={diasComAnomalias}
                gradient="from-yellow-500 via-orange-600 to-red-500"
              />
              <InfoCard
                title="Maior Venda"
                value={
                  maiorVenda
                    ? `R$ ${maiorVenda.total.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : 'N/A'
                }
                gradient="from-teal-500 via-blue-600 to-indigo-500"
              />
              <InfoCard
                title="Previsão de 30 dias"
                value={`R$ ${previsaoTotal.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                gradient="from-pink-500 via-purple-600 to-blue-500"
              />
              <InfoCard
                title="Vendas Hoje"
                value={`R$ ${vendasHoje.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                gradient="from-indigo-500 via-blue-600 to-teal-500"
              />
              <InfoCard
                title="Total de Descontos"
                value={`R$ ${totalDesconto.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                gradient="from-gray-500 via-gray-600 to-gray-700"
              />
              <InfoCard
                title="Total de Frete"
                value={`R$ ${totalFrete.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                gradient="from-cyan-500 via-blue-600 to-green-500"
              />
            </div>
  
            {/* Seção de Gráficos */}
            <div className="fundo-card p-6 rounded-lg shadow-lg mb-8">
              <SalesChart data={filteredData} />
            </div>
  
            <div className="w-full lg:flex grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <div className="lg:w-1/3 fundo-card p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
                <p className="text-lg font-semibold text-white">Previsão de Venda</p>
                <PredictionsChart previsoes={previsoes} />
              </div>
              <div className="lg:w-1/3 fundo-card p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
                <p className="text-lg font-semibold text-white">Anomalias</p>
                <AnomaliesChart data={filteredData} />
              </div>
              <div className="lg:w-1/3 fundo-card p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
                <p className="text-lg font-semibold text-white">Média Móvel</p>
                <MovingAverageChart data={mediaMovel} />
              </div>
            </div>
  
            {/* Tabela da Curva ABC */}
            <CurvaABCTable products={topProducts.topMaisVendidos || []} />
  
            {/* Seção de Recomendações – Insights para ações */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-8">
              <h2 className="text-2xl font-bold text-white mb-4">Recomendações</h2>
              {recommendations.length > 0 ? (
                <ul className="list-disc list-inside text-white text-lg">
                  {recommendations.map((rec, index) => (
                    <li key={index} className="mb-2">{rec}</li>
                  ))}
                </ul>
              ) : (
                <p className="text-white text-lg">Nenhuma recomendação no momento.</p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default PedidoMagalu;
  