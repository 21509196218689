import React from 'react';

const Filters = ({ filters, handleInputChange, fetchAllOrders }) => (
    <div className="py-8 px-4 bg-gray-100 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold text-gray-700 mb-6">Filters</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Filtro de Status */}
            <div className="flex flex-col">
                <label htmlFor="status" className="text-sm font-medium text-gray-600 mb-2">
                    Status:
                </label>
                <select
                    id="status"
                    name="status"
                    value={filters.status}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md bg-white shadow-sm focus:ring-2 focus:ring-blue-400"
                >
                    <option value="">All</option>
                    <option value="new">New</option>
                    <option value="approved">Approved</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="finished">Finished</option>
                </select>
            </div>

            {/* Filtro de Data Inicial */}
            <div className="flex flex-col">
                <label htmlFor="purchased_at__gte" className="text-sm font-medium text-gray-600 mb-2">
                    Purchased At (From):
                </label>
                <input
                    id="purchased_at__gte"
                    type="date"
                    name="purchased_at__gte"
                    value={filters.purchased_at__gte}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md bg-white shadow-sm focus:ring-2 focus:ring-blue-400"
                />
            </div>

            {/* Filtro de Data Final */}
            <div className="flex flex-col">
                <label htmlFor="purchased_at__lte" className="text-sm font-medium text-gray-600 mb-2">
                    Purchased At (To):
                </label>
                <input
                    id="purchased_at__lte"
                    type="date"
                    name="purchased_at__lte"
                    value={filters.purchased_at__lte}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md bg-white shadow-sm focus:ring-2 focus:ring-blue-400"
                />
            </div>
        </div>

        {/* Botão de Ação */}
        <div className="mt-6 flex justify-center">
            <button
                onClick={fetchAllOrders}
                className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-200"
            >
                Fetch Orders
            </button>
        </div>
    </div>
);

export default Filters;
