import React, { useState, useEffect, useContext, useMemo } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import dayjs from 'dayjs';
import { db } from '../components/Firebase';
import { DadosContext } from '../common/DadosContext';
import * as XLSX from 'xlsx';

const CurvaABCTable = ({ products }) => {
  const { user, tokens } = useContext(DadosContext);
  // Dados combinados (antes dos filtros)
  const [allCombinedProducts, setAllCombinedProducts] = useState([]);
  // Produtos filtrados e com KPIs calculados
  const [productsWithNames, setProductsWithNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Estados do modal de detalhes
  const [modalData, setModalData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  // Estados dos filtros: datas, pesquisa, mês e período rápido
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [quickFilter, setQuickFilter] = useState(''); // Valores: "7", "15", "30", "60", "90" ou ''

  // -------------------------------------------------------------------------
  // 1. Busca os nomes dos produtos no Firebase e junta com os dados recebidos.
  useEffect(() => {
    if (!user || !products) return;

    const fetchProductNames = async () => {
      try {
        setLoading(true);
        setError(null);

        // Busca os produtos cadastrados no Firebase
        const productsRef = collection(db, `users/${user.uid}/magaluprodutos`);
        const querySnapshot = await getDocs(productsRef);

        // Cria um dicionário: chave = código do produto, valor = nome do produto
        const firebaseProducts = querySnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          if (data.code && data.title?.pt_BR) {
            acc[data.code.trim().toUpperCase()] = data.title.pt_BR.trim();
          }
          return acc;
        }, {});

        // Junta os produtos vindos via prop com os nomes do Firebase
        const combinedProducts = products.map((product) => {
          const skuNormalized = product.sku?.trim().toUpperCase();
          const nomeEncontrado = firebaseProducts[skuNormalized] || 'Nome não encontrado';
          return { ...product, nome: nomeEncontrado };
        });

        // Armazena os dados combinados (antes dos filtros)
        setAllCombinedProducts(combinedProducts);
      } catch (err) {
        console.error('Erro ao buscar nomes dos produtos:', err);
        setError('Erro ao buscar nomes dos produtos.');
      } finally {
        setLoading(false);
      }
    };

    fetchProductNames();
  }, [user, products]);

  // -------------------------------------------------------------------------
  // 2. Atualiza os filtros de data com base no filtro rápido selecionado.
  useEffect(() => {
    if (quickFilter) {
      const days = parseInt(quickFilter, 10);
      const newStart = dayjs().subtract(days, 'day').format('YYYY-MM-DD');
      const newEnd = dayjs().format('YYYY-MM-DD');
      setStartDate(newStart);
      setEndDate(newEnd);
      // Opcional: limpar outros filtros (como selectedMonth) se necessário
      setSelectedMonth('');
    }
  }, [quickFilter]);

  // -------------------------------------------------------------------------
  // 3. Calcula os meses disponíveis a partir dos produtos combinados.
  // Usa a propriedade createdAt ou, se não existir, purchasedAt.
  const availableMonths = useMemo(() => {
    if (!allCombinedProducts.length) return [];
    const monthsSet = new Set();
    allCombinedProducts.forEach((product) => {
      const date = product.createdAt || product.purchasedAt;
      if (date) {
        monthsSet.add(dayjs(date).format("YYYY-MM"));
      }
    });
    return Array.from(monthsSet).sort((a, b) => a.localeCompare(b));
  }, [allCombinedProducts]);

  // -------------------------------------------------------------------------
  // 4. Aplica os filtros (por data, mês, período rápido e pesquisa) e recalcula os KPIs.
  useEffect(() => {
    if (!allCombinedProducts.length) return;

    let filtered = allCombinedProducts;

    // Filtra por data, usando createdAt ou purchasedAt se disponíveis
    if (startDate && endDate && (allCombinedProducts[0].createdAt || allCombinedProducts[0].purchasedAt)) {
      filtered = filtered.filter((product) => {
        const dateValue = product.createdAt || product.purchasedAt;
        const productDate = new Date(dateValue);
        return productDate >= new Date(startDate) && productDate <= new Date(endDate);
      });
    }

    // Se um mês específico foi selecionado, filtra os produtos cujo mês (YYYY-MM) corresponde
    if (selectedMonth) {
      filtered = filtered.filter((product) => {
        const dateValue = product.createdAt || product.purchasedAt;
        return dateValue && dayjs(dateValue).format("YYYY-MM") === selectedMonth;
      });
    }

    // Aplica o filtro de pesquisa por SKU ou nome
    if (searchQuery) {
      filtered = filtered.filter((product) =>
        (product.sku &&
          product.sku.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (product.nome &&
          product.nome.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }

    if (filtered.length === 0) {
      setProductsWithNames([]);
      return;
    }

    // Cálculo dos KPIs:
    const totalVendas = filtered.reduce((acc, prod) => acc + prod.total, 0);
    const totalPedidos = filtered.reduce(
      (acc, prod) => acc + (prod.quantidade || 0),
      0
    );

    let acumuladoPercent = 0;
    let acumuladoValor = 0;
    const produtosABC = filtered
      .sort((a, b) => b.total - a.total)
      .map((produto) => {
        const contribuicao = (produto.total / totalVendas) * 100;
        acumuladoPercent += contribuicao;
        acumuladoValor += produto.total;
        const participacaoPedidos = totalPedidos
          ? (produto.quantidade / totalPedidos) * 100
          : 0;
        return {
          ...produto,
          contribuicao: contribuicao.toFixed(2),            // % de contribuição
          acumulado: acumuladoPercent.toFixed(2),             // % acumulado
          acumuladoValor: acumuladoValor.toFixed(2),          // Valor acumulado (R$)
          participacaoPedidos: participacaoPedidos.toFixed(2),// % de participação em pedidos
          categoria: acumuladoPercent <= 80 ? 'A' : acumuladoPercent <= 95 ? 'B' : 'C',
        };
      });

    setProductsWithNames(produtosABC);
  }, [allCombinedProducts, startDate, endDate, searchQuery, selectedMonth]);

  // -------------------------------------------------------------------------
  // Função para buscar detalhes de um produto ao clicar no SKU
  const fetchProductDetails = async (sku) => {
    const token = tokens.find((t) => t.id === 'magalu')?.token;
    if (!token) {
      setError('Token não encontrado');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch('https://nerp.com.br/magalu/sku', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ accessToken: token, code__in: sku }),
      });

      if (!response.ok) throw new Error('Erro ao buscar detalhes do produto');
      const data = await response.json();
      setModalData(data.results[0]);
      setModalVisible(true);
    } catch (err) {
      console.error(err);
      setError('Erro ao buscar detalhes do produto.');
    } finally {
      setLoading(false);
    }
  };

  // Função para fechar o modal de detalhes
  const closeModal = () => {
    setModalVisible(false);
    setModalData(null);
  };

  // Função para formatar valores em moeda BRL
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  // Exporta os dados exibidos para um arquivo Excel
  const exportToExcel = () => {
    if (!productsWithNames || productsWithNames.length === 0) return;
    const ws = XLSX.utils.json_to_sheet(productsWithNames);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'CurvaABC');
    XLSX.writeFile(wb, 'curva_abc.xlsx');
  };

  return (
    <div className="fundo-card p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out mb-8">
      {/* Cabeçalho com título, filtros e botão de exportação */}
      <div className="flex flex-col md:flex-row items-center justify-between mb-4 gap-4">
        <h2 className="text-2xl font-bold text-white">Curva ABC</h2>
        <div className="flex flex-col md:flex-row gap-4 items-center">
          {/* Filtros de data manual */}
          <div className="flex items-center gap-2">
            <label className="text-white">Data Inicial:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => {
                setQuickFilter(''); // Limpa filtro rápido se alterado manualmente
                setStartDate(e.target.value);
              }}
              className="p-2 rounded"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="text-white">Data Final:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => {
                setQuickFilter('');
                setEndDate(e.target.value);
              }}
              className="p-2 rounded"
            />
          </div>
          {/* Filtro Rápido por Período */}
          <div className="flex items-center gap-2">
            <label className="text-white">Período Rápido:</label>
            <select
              value={quickFilter}
              onChange={(e) => setQuickFilter(e.target.value)}
              className="p-2 rounded"
            >
              <option value="">Sem filtro</option>
              <option value="7">Últimos 7 dias</option>
              <option value="15">Últimos 15 dias</option>
              <option value="30">Últimos 30 dias</option>
              <option value="60">Últimos 60 dias</option>
              <option value="90">Últimos 90 dias</option>
            </select>
          </div>
          {/* Filtro por Mês Disponível */}
          <div className="flex items-center gap-2">
            <label className="text-white">Mês:</label>
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="p-2 rounded"
            >
              <option value="">Todos</option>
              {availableMonths.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          {/* Filtro de Pesquisa */}
          <div className="flex items-center gap-2">
            <label className="text-white">Pesquisar:</label>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="SKU ou Nome"
              className="p-2 rounded"
            />
          </div>
        </div>
        <button
          onClick={exportToExcel}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Exportar Excel
        </button>
      </div>

      {loading && <p className="text-center text-gray-500">Carregando...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}
      {productsWithNames.length === 0 && !loading && (
        <p className="text-center text-gray-500">Nenhum produto encontrado.</p>
      )}

      <div className="overflow-y-auto max-h-128 mt-4">
        <table className="table-auto w-full text-white">
          <thead>
            <tr>
              <th className="px-4 py-2">SKU</th>
              <th className="px-4 py-2">Total (R$)</th>
              <th className="px-4 py-2">Pedidos</th>
              <th className="px-4 py-2">Participação Pedidos (%)</th>
              <th className="px-4 py-2">Preço Médio (R$)</th>
              <th className="px-4 py-2">% Contribuição</th>
              <th className="px-4 py-2">% Acumulado</th>
              <th className="px-4 py-2">Acumulado (R$)</th>
              <th className="px-4 py-2">Categoria</th>
            </tr>
          </thead>
          <tbody>
            {productsWithNames.map((produto, index) => (
              <tr key={index} className="border-b border-gray-600 hover:bg-gray-700">
                <td
                  className="px-4 py-2 cursor-pointer text-blue-500 hover:underline"
                  onClick={() => fetchProductDetails(produto.sku)}
                >
                  {produto.sku}
                </td>
                <td className="px-4 py-2">{formatCurrency(produto.total)}</td>
                <td className="px-4 py-2">{produto.quantidade || 0}</td>
                <td className="px-4 py-2">{produto.participacaoPedidos}%</td>
                <td className="px-4 py-2">
                  {formatCurrency(produto.total / (produto.quantidade || 1))}
                </td>
                <td className="px-4 py-2">{produto.contribuicao}%</td>
                <td className="px-4 py-2">{produto.acumulado}%</td>
                <td className="px-4 py-2">{formatCurrency(produto.acumuladoValor)}</td>
                <td className="px-4 py-2">{produto.categoria}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal de Detalhes do Produto */}
      {modalVisible && modalData && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 w-full max-w-2xl p-8 rounded-lg shadow-lg relative">
            <button
              className="absolute top-4 right-4 text-gray-400 hover:text-red-500"
              onClick={closeModal}
            >
              ✕
            </button>
            <h2 className="text-2xl font-bold mb-6 text-center text-white">
              {modalData.title?.pt_BR || 'Detalhes do Produto'}
            </h2>
            <p className="mb-4 text-white">
              <strong>SKU:</strong> {modalData.code}
            </p>
            <p className="mb-4 text-white">
              <strong>Marca:</strong> {modalData.brand}
            </p>
            <p className="mb-4 text-white">
              <strong>Condição:</strong> {modalData.condition}
            </p>
            <div className="mt-6">
              <h3 className="font-bold mb-2 text-white">Mídias:</h3>
              <div className="grid grid-cols-3 gap-4">
                {modalData.medias.map((media, index) => (
                  <div key={index} className="flex items-center justify-center">
                    {media.type === 'image/jpeg' ? (
                      <img
                        src={media.reference}
                        alt={media.name}
                        className="w-32 h-32 object-cover rounded-md"
                      />
                    ) : (
                      <a
                        href={media.reference}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {media.name}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurvaABCTable;
