import React from 'react';

const OrderTable = ({ orders }) => (
    <div style={{ marginTop: '20px' }}>
        <h2>Orders Table</h2>
        <table
            border="1"
            style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse', marginTop: '10px' }}
        >
            <thead>
                <tr style={{ backgroundColor: '#f2f2f2', color: '#333' }}>
                    <th style={{ padding: '10px' }}>Order ID</th>
                    <th style={{ padding: '10px' }}>Status</th>
                    <th style={{ padding: '10px' }}>Purchased At</th>
                    <th style={{ padding: '10px' }}>Payment Method</th>
                    <th style={{ padding: '10px' }}>Total Amount</th>
                    <th style={{ padding: '10px' }}>Product Name</th>
                    <th style={{ padding: '10px' }}>Product Image</th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order) => (
                    <tr key={order.id}>
                        <td style={{ padding: '10px' }}>{order.id}</td>
                        <td style={{ padding: '10px', textTransform: 'capitalize' }}>{order.status}</td>
                        <td style={{ padding: '10px' }}>{order.purchased_at}</td>
                        <td style={{ padding: '10px', textTransform: 'capitalize' }}>
                            {order.payments?.[0]?.method || 'N/A'}
                        </td>
                        <td style={{ padding: '10px' }}>
                            R$ {(order.amounts?.total / 100 || 0).toFixed(2)}
                        </td>
                        <td style={{ padding: '10px' }}>
                            {order.deliveries?.[0]?.items?.[0]?.info?.name || 'N/A'}
                        </td>
                        <td style={{ padding: '10px' }}>
                            {order.deliveries?.[0]?.items?.[0]?.info?.images?.[0]?.url ? (
                                <img
                                    src={order.deliveries[0].items[0].info.images[0].url}
                                    alt={order.deliveries[0].items[0].info.name}
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        objectFit: 'cover',
                                        borderRadius: '5px',
                                    }}
                                />
                            ) : (
                                'No Image'
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default OrderTable;
