import React, { useContext, useMemo, useState } from 'react';
import { DadosContext } from '../../common/DadosContext';
import Filters from './Filters';
import OrderTable from './OrderTable';
import Statistics from './Statistics';
import Charts from './Charts';
import StatusValues from './StatusValues';

const OrdersDashboard = () => {
    const { tokens } = useContext(DadosContext);
    const [filters, setFilters] = useState({
        status: '',
        purchased_at__gte: '',
        purchased_at__lte: '',
        _limit: 100,
        _offset: 0,
        _sort: 'purchased_at:asc',
    });
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [stats, setStats] = useState({
        new: 0,
        approved: 0,
        cancelled: 0,
        finished: 0,
    });

    const token = useMemo(() => tokens.find((t) => t.id === 'magalu')?.token, [tokens]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const fetchAllOrders = async () => {
        setLoading(true);
        setError('');
        setOrders([]);

        try {
            const adjustDateToUTC = (date, isStartOfDay = true) => {
                const d = new Date(date);
                if (isStartOfDay) {
                    d.setUTCHours(0, 0, 0, 0);
                } else {
                    d.setUTCHours(23, 59, 59, 999);
                }
                return d.toISOString();
            };

            const formattedParams = {
                ...filters,
                purchased_at__gte: filters.purchased_at__gte
                    ? adjustDateToUTC(filters.purchased_at__gte, true)
                    : '',
                purchased_at__lte: filters.purchased_at__lte
                    ? adjustDateToUTC(filters.purchased_at__lte, false)
                    : '',
                _limit: Number(filters._limit) || 50,
                _offset: 0,
            };

            let allOrders = [];
            let offset = 0;
            let nextPage = true;

            while (nextPage) {
                const response = await fetch('https://nerp.com.br/magalu/orders', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token, params: { ...formattedParams, _offset: offset } }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch orders.');
                }

                const data = await response.json();

                allOrders = [...allOrders, ...data.results];

                const { links } = data.meta;
                if (links && links.next) {
                    offset += formattedParams._limit;
                } else {
                    nextPage = false;
                }
            }

            const formattedOrders = allOrders.map((order) => ({
                ...order,
                purchased_at: new Date(order.purchased_at).toLocaleDateString('pt-BR'),
            }));

            setOrders(formattedOrders);

            // Calculate stats
            const stats = {
                new: allOrders.filter((o) => o.status === 'new').length,
                approved: allOrders.filter((o) => o.status === 'approved').length,
                cancelled: allOrders.filter((o) => o.status === 'cancelled').length,
                finished: allOrders.filter((o) => o.status === 'finished').length,
            };
            setStats(stats);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1>Orders Dashboard</h1>
            <Filters
                filters={filters}
                handleInputChange={handleInputChange}
                fetchAllOrders={fetchAllOrders}
            />
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Statistics stats={stats} />
            <div className='flex gap-4'>
                <Charts stats={stats} />
                <StatusValues orders={orders} />
            </div>
            <OrderTable orders={orders} />
        </div>
    );
};

export default OrdersDashboard;
