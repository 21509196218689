import React, { useState, useMemo } from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Area,
  Legend,
} from 'recharts';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Agrupa os dados por mês (caso o agrupamento seja mensal)
const groupByMonth = (data) => {
  const grouped = data.reduce((acc, item) => {
    const month = format(parseISO(item.date), 'yyyy-MM');
    if (!acc[month]) {
      acc[month] = { date: month, total: 0 };
    }
    acc[month].total += item.total;
    return acc;
  }, {});
  return Object.values(grouped);
};

// Calcula KPIs adicionais para cada ponto do gráfico
const calculateKPIs = (data) => {
  let cumulativeTotal = 0;
  let maxSoFar = -Infinity;
  let minSoFar = Infinity;
  const windowSize = 7; // Período para a média móvel

  return data.map((item, index) => {
    cumulativeTotal += item.total;
    maxSoFar = Math.max(maxSoFar, item.total);
    minSoFar = Math.min(minSoFar, item.total);

    const prevTotal = index > 0 ? data[index - 1].total : 0;
    const difference = item.total - prevTotal;
    const percentage = prevTotal !== 0 ? (difference / prevTotal) * 100 : 0;
    const cumulativeAverage = cumulativeTotal / (index + 1);

    // Média móvel: média dos últimos 7 períodos (ou menos, se não houver pontos suficientes)
    const windowStart = Math.max(0, index - windowSize + 1);
    const windowData = data.slice(windowStart, index + 1);
    const movingAverage = windowData.reduce((sum, cur) => sum + cur.total, 0) / windowData.length;

    return {
      ...item,
      difference,
      percentage,
      cumulativeTotal,
      cumulativeAverage,
      maxSoFar,
      minSoFar,
      movingAverage,
    };
  });
};

// Tooltip customizado com os KPIs e formatação da data e dia da semana
const CustomTooltip = ({ active, payload, label, groupBy }) => {
  if (active && payload && payload.length) {
    let formattedLabel;
    let dayOfWeek;
    try {
      let parsed;
      if (groupBy === 'monthly') {
        // Para agrupamento mensal, utiliza o dia "01" para criar uma data válida
        parsed = parseISO(`${label}-01`);
      } else {
        parsed = parseISO(label);
      }
      formattedLabel = format(parsed, 'dd/MM/yyyy');
      dayOfWeek = format(parsed, 'eeee', { locale: ptBR });
    } catch (error) {
      formattedLabel = label;
      dayOfWeek = '';
    }

    const {
      total,
      difference,
      percentage,
      cumulativeTotal,
      cumulativeAverage,
      maxSoFar,
      minSoFar,
      movingAverage,
    } = payload[0].payload;

    return (
      <div
        style={{
          backgroundColor: '#222',
          border: '1px solid #ff00ff',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <p style={{ margin: 0, color: '#fff' }}>
          <strong>Data:</strong> {formattedLabel}
        </p>
        <p style={{ margin: 0, color: '#fff' }}>
          <strong>Dia da semana:</strong> {dayOfWeek}
        </p>
        <p style={{ margin: 0, color: '#fff' }}>
          <strong>Total:</strong> {total}
        </p>
        <p style={{ margin: 0, color: difference >= 0 ? '#0f0' : '#f00' }}>
          <strong>Diferença:</strong> {difference >= 0 ? `+${difference}` : difference}
        </p>
        <p style={{ margin: 0, color: percentage >= 0 ? '#0f0' : '#f00' }}>
          <strong>Variação:</strong> {percentage.toFixed(2)}%
        </p>
        <p style={{ margin: 0, color: '#fff' }}>
          <strong>Total Acumulado:</strong> {cumulativeTotal}
        </p>
        <p style={{ margin: 0, color: '#fff' }}>
          <strong>Média Acumulada:</strong> {cumulativeAverage.toFixed(2)}
        </p>
        <p style={{ margin: 0, color: '#fff' }}>
          <strong>Máximo até o Momento:</strong> {maxSoFar}
        </p>
        <p style={{ margin: 0, color: '#fff' }}>
          <strong>Mínimo até o Momento:</strong> {minSoFar}
        </p>
        <p style={{ margin: 0, color: '#fff' }}>
          <strong>Média Móvel (7 períodos):</strong> {movingAverage.toFixed(2)}
        </p>
      </div>
    );
  }
  return null;
};

const SalesChart = ({ data }) => {
  const [groupBy, setGroupBy] = useState('daily'); // 'daily' ou 'monthly'

  // Agrupa e calcula os KPIs (utilizando useMemo para evitar recomputações desnecessárias)
  const transformedData = useMemo(() => {
    const groupedData = groupBy === 'monthly' ? groupByMonth(data) : data;
    return calculateKPIs(groupedData);
  }, [data, groupBy]);

  // Função para formatar datas no eixo X e na Brush (dd/MM/yyyy)
  const formatDate = (value) => {
    try {
      let parsed;
      if (groupBy === 'monthly') {
        parsed = parseISO(`${value}-01`);
      } else {
        parsed = parseISO(value);
      }
      return format(parsed, 'dd/MM/yyyy');
    } catch (error) {
      return value;
    }
  };

  return (
    <div
      style={{
        color: '#ddd',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#111',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <div style={{ marginBottom: '20px' }}>
        <label style={{ marginRight: '10px' }}>Agrupar por:</label>
        <select
          value={groupBy}
          onChange={(e) => setGroupBy(e.target.value)}
          style={{
            padding: '5px',
            borderRadius: '5px',
            backgroundColor: '#222',
            color: '#ddd',
            border: '1px solid #444',
          }}
        >
          <option value="daily">Diário</option>
          <option value="monthly">Mensal</option>
        </select>
      </div>

      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={transformedData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
          <defs>
            <linearGradient id="cyberpunkGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="5%" stopColor="#ff00ff" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#00ffff" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#333" strokeDasharray="5 5" />
          <XAxis dataKey="date" tick={{ fill: '#ddd' }} tickFormatter={formatDate} />
          <YAxis tick={{ fill: '#ddd' }} />
          <Tooltip content={<CustomTooltip groupBy={groupBy} />} />
          <Legend wrapperStyle={{ color: '#ddd' }} />
          <Line
            type="monotone"
            dataKey="total"
            name="Total de Vendas"
            stroke="url(#cyberpunkGradient)"
            strokeWidth={3}
            activeDot={{ r: 8 }}
          />
          <Area type="monotone" dataKey="total" stroke="none" fill="url(#cyberpunkGradient)" fillOpacity={0.3} />
          <Brush dataKey="date" height={30} stroke="#ff00ff" tickFormatter={formatDate} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesChart;
