import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { DadosContext } from '../../common/DadosContext';
import { useAuth } from '../../common/AuthContext';
import BlingAuthButton from './Connect';

// Dados de integrações
const integracoesMarketplaces = [
  { nome: 'Bling', id: 'bling', requiresLogin: true },
  { nome: 'Mercado Livre', id: 'mercado_livre', requiresLogin: true },
  { nome: 'Magalu', id: 'magalu', requiresLogin: true },
  { nome: 'Magalu-chat', id: 'magalu-chat', requiresLogin: true },
];

const IntegracaoForm = ({ titulo, dados }) => {
  const { saveToken, tokens } = useContext(DadosContext);
  const [localTokens, setLocalTokens] = useState({});
  const [localRefreshTokens, setLocalRefreshTokens] = useState({});
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [isTokenSaved, setIsTokenSaved] = useState({});

  // Atualiza os tokens locais ao carregar o componente
  useEffect(() => {
    if (tokens) {
      const initialTokens = {};
      const initialRefreshTokens = {};
      tokens.forEach((token) => {
        initialTokens[token.id] = token.token;
        initialRefreshTokens[token.id] = token.refreshToken;
      });
      setLocalTokens(initialTokens);
      setLocalRefreshTokens(initialRefreshTokens);
    }
  }, [tokens]);

  // Função para renovar token
  const refreshToken = async (id) => {
    if (id == 'magalu') {
      try {
        const refreshToken = localRefreshTokens[id];
        if (!refreshToken) {
          setNotification({ message: 'Refresh token não encontrado.', type: 'error' });
          return;
        }

        // Chamada para o backend
        const response = await axios.post('https://nerp.com.br/magalu/refresh-token', {
          refreshToken,
        });

        console.log(response.data)

        const { access_token, refresh_token: newRefreshToken } = response.data;

        // Atualiza estados locais
        setLocalTokens((prevTokens) => ({ ...prevTokens, [id]: access_token }));
        setLocalRefreshTokens((prevTokens) => ({ ...prevTokens, [id]: newRefreshToken }));

        // Salva no Firestore
        if (saveToken) {
          saveToken(id, access_token, newRefreshToken);
          setNotification({ message: 'Token renovado e salvo com sucesso!', type: 'success' });
        }

      } catch (error) {
        console.error('Erro ao renovar token:', error);
        setNotification({ message: 'Erro ao renovar token.', type: 'error' });
      }
    }

    if (id == 'magalu-chat') {
      try {
        const refreshToken = localRefreshTokens[id];
        if (!refreshToken) {
          setNotification({ message: 'Refresh token não encontrado.', type: 'error' });
          return;
        }

        // Chamada para o backend
        const response = await axios.post('https://nerp.com.br/magalu/refresh-chat', {
          refreshToken,
        });

        console.log(response.data)

        const { access_token, refresh_token: newRefreshToken } = response.data;

        // Atualiza estados locais
        setLocalTokens((prevTokens) => ({ ...prevTokens, [id]: access_token }));
        setLocalRefreshTokens((prevTokens) => ({ ...prevTokens, [id]: newRefreshToken }));

        // Salva no Firestore
        if (saveToken) {
          saveToken(id, access_token, newRefreshToken);
          setNotification({ message: 'Token renovado e salvo com sucesso!', type: 'success' });
        }

      } catch (error) {
        console.error('Erro ao renovar token:', error);
        setNotification({ message: 'Erro ao renovar token.', type: 'error' });
      }
    }

    if (id == 'mercado_livre') {
      try {
        const refreshToken = localRefreshTokens[id];
        if (!refreshToken) {
          setNotification({ message: 'Refresh token não encontrado.', type: 'error' });
          return;
        }

        // Chamada para o backend
        const response = await axios.post('https://nerp.com.br/mercado-livre/refresh-token', {
          refreshToken,
        });

        console.log(response.data)

        const { access_token, refresh_token: newRefreshToken } = response.data;

        // Atualiza estados locais
        setLocalTokens((prevTokens) => ({ ...prevTokens, [id]: access_token }));
        setLocalRefreshTokens((prevTokens) => ({ ...prevTokens, [id]: newRefreshToken }));

        // Salva no Firestore
        if (saveToken) {
          saveToken(id, access_token, newRefreshToken);
          setNotification({ message: 'Token renovado e salvo com sucesso!', type: 'success' });
        }

      } catch (error) {
        console.error('Erro ao renovar token:', error);
        setNotification({ message: 'Erro ao renovar token.', type: 'error' });
      }
    }
  };

  return (
    <div className="bg-white shadow-md rounded p-4 mb-6">
      <h2 className="text-2xl font-bold mb-4">{titulo}</h2>
      <ul>
        {dados.map((integracao) => (
          <li key={integracao.id} className="mb-4">
            <h3 className="text-xl font-semibold">{integracao.nome}</h3>
            <div className="bg-gray-200 p-4 rounded-xl">
              <BlingAuthButton id={integracao.id} />
              {localTokens[integracao.id] && (
                <div className="mt-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Access Token:
                  </label>
                  <input
                    type="text"
                    value={localTokens[integracao.id]}
                    readOnly
                    className="p-2 border border-gray-300 rounded-md w-full"
                  />
                </div>
              )}
              {localRefreshTokens[integracao.id] && (
                <div className="mt-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Refresh Token:
                  </label>
                  <input
                    type="text"
                    value={localRefreshTokens[integracao.id]}
                    readOnly
                    className="p-2 border border-gray-300 rounded-md w-full"
                  />
                </div>
              )}
              <button
                onClick={() => refreshToken(integracao.id)}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Renovar Token
              </button>
            </div>
          </li>
        ))}
      </ul>
      {notification.message && (
        <div
          className={`mt-4 p-2 rounded ${notification.type === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
            }`}
        >
          {notification.message}
        </div>
      )}
    </div>
  );
};

const Integracoes = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 min-h-screen py-32">
      <IntegracaoForm titulo="Integrações de Marketplaces" dados={integracoesMarketplaces} />
    </div>
  );
};

export default Integracoes;
