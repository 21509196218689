import React from 'react';

const StatusValues = ({ orders }) => {
    // Soma dos valores por status
    const totalValues = orders.reduce(
        (acc, order) => {
            const amount = order.amounts?.total / 100 || 0;
            if (order.status === 'new') acc.new += amount;
            if (order.status === 'approved') acc.approved += amount;
            if (order.status === 'cancelled') acc.cancelled += amount;
            if (order.status === 'finished') acc.finished += amount;
            return acc;
        },
        { new: 0, approved: 0, cancelled: 0, finished: 0 }
    );

    return (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 p-4 bg-gray-100 rounded-lg shadow-md">
            <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-700">New Orders Value</h3>
                <p className="text-2xl font-bold text-blue-500">
                    R$ {totalValues.new.toFixed(2)}
                </p>
            </div>
            <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-700">Approved Orders Value</h3>
                <p className="text-2xl font-bold text-green-500">
                    R$ {totalValues.approved.toFixed(2)}
                </p>
            </div>
            <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-700">Cancelled Orders Value</h3>
                <p className="text-2xl font-bold text-red-500">
                    R$ {totalValues.cancelled.toFixed(2)}
                </p>
            </div>
            <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-700">Finished Orders Value</h3>
                <p className="text-2xl font-bold text-yellow-500">
                    R$ {totalValues.finished.toFixed(2)}
                </p>
            </div>
        </div>
    );
};

export default StatusValues;
