import React, { useState, useEffect } from "react";
import axios from "axios";

const email = "laiza@conthey.com.br";
const api = "SS3Q-EwyfcZz5Qcc1nbk";
const accountManagerKey = "T4hEXIhnHW";
const BASE_URL = "https://api.skyhub.com.br/sac";

const SACPage = () => {
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [chats, setChats] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loadingChats, setLoadingChats] = useState(false);
  const [loadingTickets, setLoadingTickets] = useState(true);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  // **Busca todos os SACs (carrega apenas uma vez)**
  useEffect(() => {
    const fetchSAC = async () => {
      setLoadingTickets(true);
      try {
        const response = await axios.get(BASE_URL, {
          headers: {
            "X-User-Email": email,
            "X-Api-Key": api,
            "X-Accountmanager-Key": accountManagerKey,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        setTickets(response.data.sacs || []);
      } catch (error) {
        console.error("Erro ao buscar SAC:", error);
      }
      setLoadingTickets(false);
    };

    fetchSAC();
  }, []); // ✅ Carrega apenas uma vez ao iniciar a página

  // **Busca o chat de um ticket SEM RECARREGAR TODOS OS TICKETS**
  const fetchChats = async (code) => {
    setLoadingChats(true);
    setError("");
    setSelectedTicket(code);
    setIsModalOpen(true);

    const encodedCode = encodeURIComponent(code);

    try {
      const response = await axios.get(`${BASE_URL}/${encodedCode}/chats`, {
        headers: {
          "X-User-Email": email,
          "X-Api-Key": api,
          "X-Accountmanager-Key": accountManagerKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setChats(response.data.chats || []);
    } catch (error) {
      console.error("Erro ao buscar chats:", error);
      setError("Não foi possível carregar os chats para este pedido.");
      setChats([]);
    }
    setLoadingChats(false);
  };

  // **Envia uma mensagem no chat SEM RECARREGAR TODOS OS TICKETS**
  const sendMessage = async () => {
    if (!newMessage.trim()) return;
    setLoadingChats(true);

    const encodedCode = encodeURIComponent(selectedTicket);
    const parts = "CUSTOMER_SELLER";

    const messageData = {
      deliveryId: selectedTicket.split("-").pop(),
      identityId: "12345678901234", // **CNPJ do seller**
      messageTo: "TO_CUSTOMER",
      messageType: "TEXT_MESSAGE",
      orderId: selectedTicket,
      message: newMessage,
    };

    try {
      await axios.post(`${BASE_URL}/${encodedCode}/chats/${parts}`, messageData, {
        headers: {
          "X-User-Email": email,
          "X-Api-Key": api,
          "X-Accountmanager-Key": accountManagerKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setNewMessage("");
      fetchChats(selectedTicket); // 🔄 Atualiza apenas os chats, SEM tocar nos tickets
    } catch (error) {
      console.error("Erro ao enviar mensagem:", error);
      setError("Não foi possível enviar a mensagem.");
    }
    setLoadingChats(false);
  };

  return (
    <div className="p-6 py-36 text-red-500">
      <h1 className="text-2xl font-bold mb-4">Consulta de SAC - SkyHub</h1>

      {/* Lista de Tickets */}
      {loadingTickets ? (
        <p>Carregando tickets...</p>
      ) : tickets.length > 0 ? (
        <table className="w-full border">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2">Código</th>
              <th className="border p-2">Pedido</th>
              <th className="border p-2">Status</th>
              <th className="border p-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket) => (
              <tr key={ticket.code} className="text-center border">
                <td className="border p-2">{ticket.code}</td>
                <td className="border p-2">{ticket.order_code}</td>
                <td className="border p-2">{ticket.status}</td>
                <td className="border p-2">
                  <button
                    onClick={() => fetchChats(ticket.code)}
                    className="p-1 bg-purple-500 text-white rounded"
                  >
                    Ver Chats
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Nenhum ticket encontrado.</p>
      )}

      {/* Modal para exibir o chat */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
          <div className="bg-white p-6 rounded-lg w-96 shadow-lg h-96 overflow-hidden overflow-y-scroll">
            <h2 className="text-xl font-bold mb-2">Chats do Pedido - {selectedTicket}</h2>

            {error && <p className="text-red-500">{error}</p>}

            {loadingChats ? (
              <p>Carregando chats...</p>
            ) : chats.length > 0 ? (
              chats.map((chat, index) => (
                <div key={index} className="border p-2 my-2">
                  <p><strong>Última mensagem:</strong> {new Date(chat.last_message).toLocaleString()}</p>
                  {chat.messages.map((msg, idx) => (
                    <div key={idx} className="border p-2 my-2 bg-gray-100 rounded">
                      <p><strong>De:</strong> {msg.part.name} ({msg.part.role})</p>
                      <p><strong>Mensagem:</strong> {msg.content}</p>
                      <p><strong>Data:</strong> {new Date(msg.created_at).toLocaleString()}</p>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p>Nenhuma mensagem encontrada para este pedido.</p>
            )}

            {/* Envio de Mensagem */}
            <div className="mt-4">
              <textarea
                className="w-full p-2 border rounded"
                placeholder="Digite sua mensagem..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <button onClick={sendMessage} className="p-2 bg-green-500 text-white rounded mt-2">
                Enviar Mensagem
              </button>
            </div>

            {/* Botão para Fechar o Modal */}
            <div className="mt-4 flex justify-end">
              <button onClick={() => setIsModalOpen(false)} className="p-2 bg-red-500 text-white rounded">
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SACPage;
