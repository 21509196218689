import { useState } from "react";

function Trends() {
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState(null);

  const fetchTrends = async () => {
    const response = await fetch(`https://nerp.com.br/seo/trends?keyword=${keyword}`);
    const result = await response.json();
    console.log(result)
    setData(result);
  };

  return (
    <div>
      <input value={keyword} onChange={(e) => setKeyword(e.target.value)} />
      <button onClick={fetchTrends}>Buscar Tendências</button>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
}

export default Trends;
