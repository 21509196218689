import React from 'react';
import { CircularProgress } from '@mui/material';

export const Loader = ({ message = "Carregando módulos...", size = 50, thickness = 4 }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen animate-gradient-background">
      {/* Spinner */}
      <div className="relative flex items-center justify-center mb-6">
        <CircularProgress
          size={size}
          thickness={thickness}
          sx={{
            color: '#ffffff', // Personalizando a cor do spinner
          }}
        />
        {/* Efeito de Glow em volta do Spinner */}
        <div className="absolute w-full h-full rounded-full blur-xl opacity-50 bg-white animate-pulse-glow"></div>
      </div>
      {/* Mensagem com animação de glow */}
      <p className="text-white text-lg font-semibold animate-text-glow">{message}</p>
    </div>
  );
};
