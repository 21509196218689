import React, { useEffect, useRef } from 'react';

export const HeroSection = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    let lines = [];
    const lineCount = 10;
    const waveHeight = 30;
    const speed = 0.005;
    let animationProgress = 3;

    const waveConfig = {
      verticalOffset: 200,
      verticalSpread: 100,
      centeringFactor: 0.5,
      setVerticalPosition(offset) {
        this.verticalOffset = offset;
      }
    };

    function initializeWaves() {
      lines = [];
      for (let i = 0; i < lineCount; i++) {
        const baseVerticalOffset =
          (i - (lineCount - 1) * waveConfig.centeringFactor) * waveConfig.verticalSpread;
        lines.push({
          frequency: 0.015 + i * 0.005,
          amplitude: waveHeight - i * 10,
          phase: (i * Math.PI) / 200,
          verticalOffset: baseVerticalOffset + waveConfig.verticalOffset
        });
      }
    }

    let animationFrameId;

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      animationProgress += speed;
      const effectiveWaveWidth = canvas.width;

      lines.forEach((line, index) => {
        // Ajuste dos valores de alpha para tornar as linhas mais visíveis
        const gradient = ctx.createLinearGradient(0, 0, effectiveWaveWidth, 0);
        gradient.addColorStop(0, `rgba(138, 103, 187, ${0.8 - index * 0.05})`);
        gradient.addColorStop(1, `rgba(138, 103, 187, ${0.5 - index * 0.02})`);

        ctx.beginPath();
        ctx.lineWidth = 1.5;
        ctx.strokeStyle = gradient;

        for (let x = 0; x <= effectiveWaveWidth; x += 2) {
          const mergeMultiplier = 1 - (x / effectiveWaveWidth) * 0.8;
          const yOffset = line.verticalOffset * mergeMultiplier * (x / effectiveWaveWidth);
          const y =
            canvas.height / 2 +
            yOffset +
            Math.sin(x * line.frequency + animationProgress + line.phase) *
              (line.amplitude * mergeMultiplier);

          x === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);
        }
        ctx.stroke();

        // Ponto animado na onda
        const dotX = effectiveWaveWidth * ((animationProgress + line.phase) % 1);
        const mergeMultiplierDot = 1 - (dotX / effectiveWaveWidth) * 0.8;
        const yOffsetDot = line.verticalOffset * mergeMultiplierDot * (dotX / effectiveWaveWidth);
        const dotY =
          canvas.height / 2 +
          yOffsetDot +
          Math.sin(dotX * line.frequency + animationProgress + line.phase) *
            (line.amplitude * mergeMultiplierDot);

        ctx.beginPath();
        ctx.arc(dotX, dotY, 5, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(80, 162, 171, ${0.5 * mergeMultiplierDot})`;
        ctx.fill();
      });

      animationFrameId = requestAnimationFrame(animate);
    }

    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      initializeWaves();
    }

    function initWaveAnimation() {
      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();
      animate();
    }

    // Controles opcionais para ajustar as ondas
    const waveControls = {
      moveUp(amount = 50) {
        waveConfig.setVerticalPosition(waveConfig.verticalOffset - amount);
        initializeWaves();
      },
      moveDown(amount = 50) {
        waveConfig.setVerticalPosition(waveConfig.verticalOffset + amount);
        initializeWaves();
      },
      reset() {
        waveConfig.setVerticalPosition(0);
        initializeWaves();
      },
      setCenteringFactor(factor = 0.5) {
        waveConfig.centeringFactor = Math.max(0, Math.min(1, factor));
        initializeWaves();
      }
    };

    // Ajuste inicial – move as ondas para baixo
    waveControls.moveDown(200);
    initWaveAnimation();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="bg-[#0a0a1a] text-white py-16">
      <div className="relative min-h-screen overflow-hidden">
        {/* Canvas de fundo */}
        <canvas
          ref={canvasRef}
          id="waveCanvas"
          className="absolute inset-0 w-full h-full opacity-80 z-0"
        ></canvas>

        {/* Glitch Overlay (opcional) */}
        <div className="absolute z-10"></div>

        {/* Conteúdo */}
        <div className="relative z-20 container mx-auto px-4 md:px-6 lg:px-12 pt-12 md:pt-24 pb-8 md:pb-16">
          {/* Utilizamos um grid que em telas maiores possui 2 colunas,
              mas o bloco de conteúdo ocupará as duas colunas, centralizando os textos e botões */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 items-center">
            <div className="col-span-1 lg:col-span-2 space-y-4 md:space-y-6 text-center">
              <div className="flex items-center justify-center space-x-3 md:space-x-4 mb-2">
                <h2 className="text-lg md:text-xl font-mono text-cyan-300 tracking-widest uppercase">
                  Advanced Data
                </h2>
              </div>

              <h1 className="text-4xl md:text-5xl xl:text-6xl font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-cyan-300 to-purple-500">
                Impulsione seu negócio digital
              </h1>

              <p className="text-base md:text-xl text-gray-300 leading-relaxed max-w-xl mx-auto">
                Nosso serviço de marketing digital é especializado em transformar seu e-commerce em um negócio escalável e lucrativo.
              </p>

              <div className="flex flex-col sm:flex-row sm:justify-center sm:space-x-4 space-y-4 sm:space-y-0 pt-4">
                <button className="group relative px-6 py-2 md:px-8 md:py-3 rounded-full bg-gradient-to-r from-cyan-500 to-purple-600 hover:from-cyan-600 hover:to-purple-700 transition-all duration-300 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    className="mr-2 group-hover:animate-pulse"
                  >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                  Começar agora
                  <div className="absolute inset-0 rounded-full border-2 border-cyan-300/50 animate-ping group-hover:opacity-0 transition-opacity"></div>
                </button>

                <button className="border border-white/20 hover:bg-white/10 px-6 py-2 md:px-8 md:py-3 rounded-full transition-all flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    className="mr-2"
                  >
                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                  </svg>
                  Saiba Mais
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

