import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Charts = ({ stats }) => (
    <div className="flex justify-center items-center bg-gray-100 p-6 rounded-lg shadow-md">
        <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-center text-lg font-semibold text-gray-700 mb-4">
                Order Distribution
            </h3>
            <PieChart width={400} height={400} className="mx-auto">
                <Pie
                    data={[
                        { name: 'New', value: stats.new },
                        { name: 'Approved', value: stats.approved },
                        { name: 'Cancelled', value: stats.cancelled },
                        { name: 'Finished', value: stats.finished },
                    ]}
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    innerRadius={60}
                    dataKey="value"
                >
                    {COLORS.map((color, index) => (
                        <Cell key={`cell-${index}`} fill={color} />
                    ))}
                </Pie>
                <Tooltip contentStyle={{ backgroundColor: '#fff', borderRadius: '8px' }} />
                <Legend
                    verticalAlign="bottom"
                    height={36}
                    wrapperStyle={{ fontSize: '14px', color: '#555' }}
                />
            </PieChart>
        </div>
    </div>
);

export default Charts;
